import React from 'react'
import { Button, ImageSize } from './style'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { IFolhaDePagamentoProps } from '../../types'

const Automate = ({ utmLink }: IFolhaDePagamentoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row align-items-center'>
          <ImageSize className='col-12 col-md-6'>
            <img
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/automatize-integracoes/image.webp'
              alt='Notebook com o Internet Banking Empresas aberto na tela de exibição de serviços VANs disponíveis.'
            />
          </ImageSize>
          <div className='col-12 col-md-6 mt-4 mt-md-0'>
            <h2 className='fs-24 fs-xl-32 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-graysvcale--500 fw-600'>
              Automatize a troca de arquivos com a segurança das VANs
            </h2>
            <p className='fs-16 lh-20 text-graysvcale--500 mt-4 mb-5 mb-lg-4'>Envie seus arquivos de remessa e receba os retornos do Inter automaticamente através das nossas redes privadas parceiras: Nexxera, Accesstage e Finnet.</p>
            <Button
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Automatize a troca de arquivos com a segurança das VANs',
                  element_name: 'Abrir conta gratuita',
                  element_action: 'click button',
                  redirect_url: utmLink,
                })
              }}
              title='Abrir conta gratuita'
              href={utmLink}
              target='blank'
              className='btn-lg btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none'
            >
              Abrir conta gratuita
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Automate
