import React from 'react'

// Components
import Faq from 'src/components/Faq/index'

import { FAQSection } from './style'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FaqSection = (props: Faq) => {
  return (
    <FAQSection id='perguntas-frequentes' className='pt-5'>
      <div className='container pt-lg-2 pt-xl-5'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3 text-xl-center'>Dúvidas frequentes</h3>
          </div>
          <Faq
            answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
          />
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqSection
