import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${green['500']};

  @media ${device.tablet} {
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/hero-integracoes-md/image.webp");
    height: 512px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
  }

  @media ${device.desktopLG} {
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/hero-integracoes-lg/image.webp");
    height: 584px;
  }

  @media ${device.desktopXL} {
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/hero-integracoes-xl/image.webp");
    height: 616px;
  }

  a { 
    text-transform: none;
    width: 100%;
    height: 48px;
    display: table;
    align-items: center;
    display: flex;
    justify-content: center;

    @media ${device.tablet} {
      width: 336px;
    }

    @media ${device.desktopLG} {
      width: 376px;
    }

    @media ${device.desktopXL} {
      width: 449px;
    }
   }
  
  button { 
    border: none;
    width: 100%;
    height: 48px;
    
    @media ${device.tablet} {
      width: 336px;
    }

    @media ${device.desktopLG} {
      width: 376px;
    }

    @media ${device.desktopXL} {
      width: 449px;
    }
   } 

`
