import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import OurServices from './sections/our-services/_index'
import Simplify from './sections/simplify/_index'
import Automate from './sections/automate/_index'
import UseTheInter from './sections/use-the-inter/_index'
import FAQ from './sections/faq/_index'

import { Wrapper } from './style'

const AntecipacaoRecebiveis = () => {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })
  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }
  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        <Hero utmLink={utmLink} />
        <OurServices />
        <Simplify />
        <Automate utmLink={utmLink} />
        <UseTheInter />
        <FAQ faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default AntecipacaoRecebiveis
