import React from 'react'

// Components
import ScrollTo from 'src/components/ScrollTo'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'
import { IFolhaDePagamentoProps } from '../../types'

const Hero = ({ utmLink }: IFolhaDePagamentoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white fw-600'>Simplifique suas <span className='d-lg-block'>integrações com</span> o Inter Empresas</h1>
            <p className='fs-16 fs-xl-18 lh-20 lh-xl-22 text-white mt-3 mb-4 mb-md-5'>
              <span className='d-lg-block'>Abra sua conta PJ 100% digital e gratuita, integre</span>
              <span className='d-lg-block'>com seu sistema de gestão e tenha mais</span>
              agilidade na operação do seu negócio
            </p>
            <ScrollTo
              to='#services'
              section='dobra_01'
              sectionName='Simplifique suas integrações com o Inter Empresas'
              elementName='Conheça as integrações'
              title='Conheça as integrações'
            >
              <button className='btn--lg btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mt-5 mt-md-3 mt-xl-4 mb-5'>Conheça as integrações</button>
            </ScrollTo>
            <a
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Simplifique suas integrações com o Inter Empresas',
                  element_name: 'Abra sua conta',
                  element_action: 'click button',
                  redirect_url: utmLink,
                })
              }}
              title='Abra sua conta'
              href={utmLink}
              target='blank'
              className='btn--outline btn--lg btn--orange rounded-3 fs-14 fw-700 mt-3 mt-md-1'
            >
              Abra sua conta
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
