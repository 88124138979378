import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  .react-multiple-carousel__arrow--left {
    @media ${device.tablet} {
      left: -10px;
    }
    @media ${device.desktopLG} {
      left: 230px;
    }
    @media ${device.desktopXL} {
      left: 276px;
    }
    @media ${device.desktopXXXL} {
      left: 420px;
    }
  }
  .react-multiple-carousel__arrow--right {
    @media ${device.tablet} {
      right: -10px;
    }
    @media ${device.desktopLG} {
      right: 230px;
    }
    @media ${device.desktopXL} {
      right: 276px;
    }
    @media ${device.desktopXXXL} {
      right: 420px;
    }
  }


  .react-multi-carousel-dot--active button {
    background: ${orange.extra} !important;
    opacity: 1 !important;
  }

  .react-multi-carousel-dot-list .react-multi-carousel-dot button {
    background: ${orange.extra} !important;
    opacity: 0.5;
  }
`

export const Button = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.desktopLG} {
    width: 456px;
    margin: 0 auto;
  }

  @media ${device.desktopXL} {
    width: 552px;
  }
`

export const Card = styled.div`
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 9px 10px -3px;
  border: 2px solid #F5F6FA;

  @media ${device.tablet} {
    height: 419px;
  }

  @media ${device.desktopLG} {
    height: 348px;
  }

  @media ${device.desktopXL} {
    height: 348px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`
