import React from 'react'
import ReactCardCarousel from 'react-card-carousel'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import useTheInterJSON from '../../assets/data/useTheInter.json'

import { Card, ContainerCard } from './style'

type UseTheInterProps = {
  image: string;
  description: string;
  altText: string;
}

const UseTheInter = () => {
  const data = usePageQuery()
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-center text-lg-left'>
              <span className='d-lg-block'>Empresas que</span> utilizam o Inter
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--500 my-4 text-center text-lg-left'>Conheça algumas empresas que já simplificam suas transações com as soluções de integração do Inter Empresas:</p>
          </div>
          <ContainerCard className='col-12'>
            <ReactCardCarousel
              alignment='vertical'
              autoplay={true}
              autoplay_speed={17000}
              disable_box_shadow={true}
              disable_keydown={false}
              spread='narrow'
            >
              {
                useTheInterJSON.map((item: UseTheInterProps) => (
                  <Card key={item.description}>
                    <div className='row px-4 py-4 align-items-md-center'>
                      <div className='col-12 col-md-3 col-lg-12 col-xl-3 mb-4 d-lg-flex justify-content-lg-center'>
                        <Img fluid={data[item.image].fluid} alt={item.altText} />
                      </div>
                      <div className='col-12 col-md-9 col-lg-12 col-xl-9'>
                        <p className='lh-16 lh-md-20 fw-400' dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </div>
                  </Card>
                ))
              }
            </ReactCardCarousel>
          </ContainerCard>
        </div>
      </div>
    </section>
  )
}

export default UseTheInter
