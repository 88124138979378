import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      virtualSistemas: imageSharp(fluid: {originalName: { regex: "/virtual-sistemas/" }}) {
        fluid(maxWidth: 216, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      },
      tecnoservice: imageSharp(fluid: {originalName: { regex: "/tecnoservice/" }}) {
        fluid(maxWidth: 180, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
    }
  `)

  return pageQuery
}

export default PageQuery
