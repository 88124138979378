import React from 'react'
import Bank from '../../assets/images/bank.png'

// Hooks
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import ourServicesJSON from '../../assets/data/ourServices.json'

import { Section, Card, Button } from './style'

type OurServicesProps = {
  title: string;
  description: string;
  icon: string;
};

const OurServices = () => {
  const windowWidth = useWidth(300)
  const link = 'https://blog.inter.co/apis-do-inter'
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleDatalayer = (item: OurServicesProps) => {
    sendDatalayerEvent({
      section: 'dobra_02',
      element_action: 'click button',
      element_name: `${item.title} - Saber mais`,
      section_name: 'Alguns de nossos serviços',
    })
  }

  return (
    <Section id='services' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-md-center mb-4'>
              Alguns de nossos serviços
            </h2>
            {windowWidth > WIDTH_XL
              ? (
                <>
                  <p className='fs-18 lh-22 text-grayscale--500'>
                    Através das APIs, a sua empresa poderá criar aplicações que se
                    comunicam diretamente com o Inter, sem a necessidade de acessar o
                    Internet Banking ou o Super App.Isso significa que você pode
                    conectar o seu ERP e ter uma experiência completa.
                  </p>
                  <p className='fs-18 lh-22 text-grayscale--500'>
                    No Portal do Desenvolvedor, você encontra toda a documentação de referência, guias, material de apoio e um fórum para trocar
                    experiências com outros usuários.
                  </p>
                </>
                )
              : ''
            }
            {windowWidth < WIDTH_MD ? (
              <div>
                {ourServicesJSON.map((item: OurServicesProps) => (
                  <Card key={item.title} className='mb-4 px-4 py-4'>
                    <div className='d-flex justify-content-start'>
                      {item.icon === 'bank' ? <img src={Bank} /> : <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} />}
                      <h3 className='fs-16 fs-md-25 lh-20 text-grayscale--500 fw-600 ml-2'>{item.title}</h3>
                    </div>
                    <div><p className='fs-14 fs-md-16 lh-17 lh-md-20 text-grayscale--400 fw-400' dangerouslySetInnerHTML={{ __html: item.description }} /></div>
                    <div>
                      <a
                        onClick={() => handleDatalayer(item)}
                        href={link}
                        target='blank'
                        className='fs-14 fs-md-20 lh-md-20 text-orange--extra fw-700'
                        title='Saber mais'
                      >Saber mais
                      </a>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <DefaultCarousel
                md={{ items: 2, partialVisibilityGutter: 90 }}
                lg={{ items: 2, partialVisibilityGutter: 120 }}
                xl={{ items: 3, partialVisibilityGutter: 60 }}
              >
                {ourServicesJSON.map((item: OurServicesProps) => (
                  <div className='px-md-2' key={item.title}>
                    <Card className='mb-4 px-4 pt-4 pb-3 d-flex flex-column justify-content-between'>
                      <div>
                        <div>
                          {item.icon === 'bank' ? <img src={Bank} /> : <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} />}
                          <h3 className='fs-16 fs-md-20 lh-20 lh-md-25 text-grayscale--500 fw-600 mt-3'>{item.title}</h3>
                        </div>
                        <div><p className='fs-14 fs-md-16 lh-17 lh-md-22 text-grayscale--400 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} /></div>
                      </div>
                      <div>
                        <a
                          onClick={() => handleDatalayer(item)}
                          href={link}
                          target='blank'
                          className='fs-14 fs-md-20 lh-md-20 text-orange--extra fw-700'
                          title='Saber mais'
                        >Saber mais
                        </a>
                      </div>
                    </Card>
                  </div>
                ))}
              </DefaultCarousel>
            )}
            <Button
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Alguns de nossos serviços',
                  element_name: 'Quero saber como integrar',
                  element_action: 'click button',
                  redirect_url: 'https://developers.inter.co/',
                })
              }}
              title='Quero saber como integrar'
              href='https://developers.inter.co/'
              target='blank'
              className='btn-lg btn-orange--extra text-white rounded-3 fs-14 fw-600 text-none mt-5'
            >
              Quero saber como integrar
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OurServices
