import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${green['500']};
  height: 752px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/simplifique-integracoes-xl/image.webp');
  background-repeat: no-repeat;
  background-size: contain;

  @media ${device.tablet} {
    height: 432px;
    background-position: top left;
    background-size: 370px;
  }

  @media ${device.desktopLG} {
    height: 521px;
    background-position: top left;
    background-size: 510px;
  }

  @media ${device.desktopXL} {
    height: 664px;
    background-size: auto;
  }

  @media ${device.desktopXXXL} {
    height: 900px;
    background-size: 900px;
  }
`

export const Circle = styled.div`
  width: 34px;
  height: 31px;
  background-color: #CCD6D3;
  border-radius: 20px;

  svg {
    width: 16px;
    height: 16px;
  }
`
