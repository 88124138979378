import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Button = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 324px;
  }

  @media ${device.desktopLG} {
    width: 454px;
  }

  @media ${device.desktopXL} {
    width: 552px;
  }
`

export const ImageSize = styled.div`
  img {
    width: 100%;
  }
`
