import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

export const Card = styled.section`
  background-color: ${white};
  border: 1px solid #F5F6FA;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);

  p {
    font-size: 14px;

    @media ${device.tablet} {
      font-size: 16px;
    }

    @media ${device.desktopLG} {
      font-size: 14px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
    }
  }

  @media ${device.tablet} {
    width: 608px;
  }

  @media ${device.desktopLG} {
    width: 556px;
  }
`

export const ContainerCard = styled.div`
  position: relative;
  height: 430px;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;

  @media ${device.tablet} {
    height: 300px;
  }
`
