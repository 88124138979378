import React from 'react'
import Note from '../../assets/images/note-detail.png'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import simplifyJSON from '../../assets/data/simplify.json'

import { Section, Circle } from './style'

type SimplifyProps = {
  icon: string;
  description: string;
}

const Simplify = () => {
  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row align-items-end'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600'>
              Simplifique sua rotina com arquivos CNAB e planilhas.
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-white my-4'>Realize as operações do seu negócio em lotes e tenha uma rotina muito mais dinâmica. Veja os modelos de arquivos que você pode utilizar com o Inter Empresas: </p>
            {
              simplifyJSON.map((item: SimplifyProps) => (
                <div key={item.description} className='d-flex align-items-center mb-3'>
                  <Circle className='d-flex align-items-center justify-content-center'>
                    {item.icon === 'note-detail' ? <img src={Note} /> : <OrangeIcon size='MD' color='#07605B' icon={item.icon} />}
                  </Circle>
                  <div className='w-100 ml-3'><p className='fs-14 fs-md-16 lh-16 lh-md-20 text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} /></div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Simplify
